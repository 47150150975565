import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kim/Documents/MedGrocer/ep-mg-website/src/components/Layout/Layout.js";
import { graphql, navigate, Link } from "gatsby";
import classNames from "classnames";
import Img from "gatsby-image";
import MediaBox from "../components/Elements/MediaBox";
import Hero from "../components/Layout/Hero";
import Container from "../components/Layout/Container";
import Button from "../components/Elements/Button";
import SEO from "components/Layout/SEO";
import career__ops from "../../static/images/career/career__ops.png";
import career__support from "../../static/images/career/career__support.png";
import career__medical from "../../static/images/career/career__medical.png";
import styles from "../../src/components/StaticPages/utils/staticPages.module.scss";
export const query = graphql`
  query {
    career: file(relativePath: { eq: "career/career__plane--white-long.png" }) {
      childImageSharp {
        fluid(
          duotone: { highlight: "#ffffff", shadow: "#ee6423" }
          toFormat: PNG
          quality: 90
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <SEO title="Careers" mdxType="SEO" />
    <Hero background={{
      image: props.data.career.childImageSharp.fluid.src,
      position: "center"
    }} color="warning" className={classNames("py-3", styles["careers"])} size="small" mdxType="Hero">
      <Container isCentered desktop={10} fullhd={8} className="mt-2 mb-2" mdxType="Container">
        <div className={styles["banner__body"]}>
          <h1>Delivering healthcare everywhere</h1>
          <p className="mt-2 subtitle is-size-7-tablet is-size-7-mobile">
            <p>{`MedGrocer's technology platforms optimize "medicine-as-a-service" for patients and companies. Its ePharmacy, corporate health services, and patient programs empower customers to get their medicines, vaccines, and medical services conveniently, cost-effectively, and intelligently.`}</p>
          </p>
          <p className="mt-1 subtitle is-size-7-tablet is-size-7-mobile mb-2">
            <p>{`Beyond just delivering medicines, we deliver healthcare solutions: from analytics, to customized programs, to end-to-end fulfillment.`}</p>
          </p>
          <p className="mt-1 subtitle is-size-7-tablet is-size-7-mobile mb-2">
            <p>{`In partnership with the country's top employers, health insurers, and multinational drug companies, we redefine the medicine experience for thousands of patients everyday.`}</p>
          </p>
          <a href="/join">
  <Button color="primary" className={styles["banner__button"]} mdxType="Button">
    Join Us
  </Button>
          </a>
        </div>
      </Container>
    </Hero>
    <Hero className="mt-5-desktop mt-5-fullhd" mdxType="Hero">
      <Container isCentered desktop={10} fullhd={10} className="mt-5-desktop mt-5-fullhd" mdxType="Container">
        <div className="content has-text-centered pb-2">
          <h1>{`Our roles for your goals`}</h1>
        </div>
        <div className="columns is-centered content has-text-centered">
          <MediaBox title="Operations" image={{
            src: career__ops,
            alt: "ops"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Product Development`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Business Development`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Supply Chain`}</p>
              </li>
            </ul>
          </MediaBox>
          <MediaBox title="Support" image={{
            src: career__support,
            alt: "support"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Finance`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Human Resources`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Developers`}</p>
              </li>
            </ul>
          </MediaBox>
          <MediaBox title="Medical" image={{
            src: career__medical,
            alt: "medical"
          }} mdxType="MediaBox">
            <ul>
              <li parentName="ul">
                <p parentName="li">{`Pharmacists`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Nurses and Doctors`}</p>
              </li>
              <li parentName="ul">
                <p parentName="li">{`Psychologists and Psychiatrists`}</p>
              </li>
            </ul>
          </MediaBox>
        </div>
      </Container>
    </Hero>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      